import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";
import { isBlocklisted } from "./helpers";
import { Row, Spin } from "antd";
import { Loader } from "./components";
// import { ethers } from 'ethers';
// import LikeButton from "./LikeButton.js";
// import { Container } from "winston";

import Banner from "./components/Banner"
import Highlights from "./components/Highlights"
import Latest from "./components/Latest"

import { Helmet } from "react-helmet";
export default function AllNfts(props) {

  let [allNfts, setAllNfts] = useState([]);
  let [nfts, setNfts] = useState({});



  return (
    <div className="allnfts-main">

      <Helmet>
        <meta charSet="utf-8" />
        <title>NFT Yard: NFT Factory and Marketplace on Ethereum L2s</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="NFT Factory and Marketplace built on Ethereum L2s where Gas prices are low" />
      </Helmet>
      <div style={{ marginTop: "20px" }}>
      </div>
      <Banner />

      <Highlights 
      address={props.address}
      />
      <Latest 
      address={props.address}
      />

    </div>
  );
}
