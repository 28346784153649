import React from 'react'
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { NavLink } from 'react-router-dom';


function handleBuyClick() {
    let widget = new RampInstantSDK({
        hostAppName: 'NFT Yard',
        hostLogoUrl: 'https://nftyard.io/logo192.png',
        variant: 'auto',
        hostApiKey: "y78mkre3wnrj59dk3ypyhc5jwfrddeuaq3b46wbc",
        selectedCountryCode: 'BR',
        defaultAsset: 'MATIC'
    });
    widget.show();
}

function Banner() {
    return (
        <div className="banner">
            <div className="text">
                <h2>
                    Create your <strong> NFT Collection</strong> in <strong>NFT Yard</strong> and <strong>OpenSea</strong>
                </h2>
            </div>
            <img src="/img-banner.png" alt="" />
            <div className="banner-footer">
                <NavLink to="/create-asset">
                    <button type="button" className="btn btn-padrao">
                        CREATE NFT
                    </button>
                </NavLink>

                <button type="button" className="btn btn-link" onClick={() => handleBuyClick()}>
                    BUY MATIC
                </button>
            </div>
        </div>
    )
}

export default Banner
