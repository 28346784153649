import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../assets/logo.svg'
// import "../styles/navigation.css"
import { Account } from './';

function Navigation({ address }) {
  return (

    <div className="topo">


      <a href="/" className="navbar-brand">
        <img src={Logo} alt="NFT Yard" className="logo-img" />
      </a>


      <nav className=" navbar-expand-md navbar-light menu">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">


          <ul className="navbar-nav nav">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/latest"> LATEST </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'/accounts/' + address}>MY ASSETS</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/holdings"> Holdings</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://docs.nftyard.io" target="_blank">DOCS</a>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/create-asset">
                <strong>CREATE</strong>
              </NavLink>
            </li>

          </ul>
        </div>
      </nav>

    </div>

  );
}

export default Navigation;
